<template>
    <div class="traceinfopage u-rela">
        <div class="u-flex u-row-around u-m-t-30 nav">
            <template v-for="(item, index) in navlist">
        <span
                class="u-font-28 u-content-color u-p-b-18"
                :class="{ active: navcur == index }"
                :key="index"
                :id="'a' + index"
                @click="handletab(index, item.url)"
        >{{ item.name }}</span
        >
            </template>
        </div>
        <div class="bggray radius-60 u-m-t-30">
            <!-- 权威监管 -->
            <div id="quanwei" name="quanwei"></div>
            <div class="quanwei u-p-40 u-rela">
                <img
                        class="imgbg imgbg1 u-abso"
                        src="../../assets/images/applet/index/bg5.png"
                        alt=""
                />
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">权威监管</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <p class="u-tips-color u-font-32 u-m-t-20">
                    当地政府通过数字农业系统及体系，对红糖生产加工和原材甘蔗种植进行全方位监管，确保产品品质优良、安全放心。
                </p>
                <div class="panel u-rela index-1">
                    <h3 class="u-flex u-row-center blod u-font-36 green4 auto">
                        政府高力度监管
                    </h3>
                    <div class="swiper u-m-t-40" id="swiper">
                        <div class="swiper-container">
                                <div class="swiper-wrapper" :images="qwjg_images">
                                    <div class="swiper-slide" v-for="(itemi,index) in qwjg_images">
                                        <div class="item" >
                                            <img :src="itemi" :data-source="itemi" :key="index" style="width: 100%"  />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="zhengshu u-rela">
                        <!-- 证书1 -->
                        <div>
                            <div
                                    v-if="flag1"
                                    class="u-flex u-m-t-30 border-b u-p-b-25 u-rela"
                            >
                                <i
                                        @click="flag1 = !flag1"
                                        class="u-abso iconfont arrow iconxiangshangyuanjiantoushangjiantouxiangshangmianxing"
                                ></i>
                                <viewer>
                                    <img
                                            class="zsimg"
                                            src="../../assets/images/applet/index/qwjg/5.jpeg"
                                            :data-source="require('../../assets/images/applet/index/qwjg/6.jpeg')"
                                            alt=""
                                    />
                                </viewer>
                                <div class="u-m-l-38 u-flex u-flex-col u-col-top">
                                    <span class="u-main-color u-font-28 u-m-b-10">CMA认证</span>
                                    <span class="u-content-color u-font-28 u-m-b-5"
                                    >检测机构：江苏佳信检测技木有限公司</span
                                    >
                                    <span class="u-content-color u-font-28 u-m-b-5"
                                    >证书编号：JX-ED-0290-484</span
                                    >
                                    <span class="u-content-color u-font-28 u-m-b-5"
                                    >签发日期：2021年12月15日</span
                                    >
                                </div>
                            </div>
                            <div v-else class="u-flex border-b">
                                <span class="u-main-color u-font-28 u-flex-1">CMA认证</span>
                                <i
                                        @click="flag1 = !flag1"
                                        class="iconfont u-font-40 green2 iconxiangshangyuanjiantoushangjiantouxiangshangmianxing-copy"
                                ></i>
                            </div>
                        </div>
                        <!-- 证书2 -->
<!--                        <div>-->
<!--                            <div-->
<!--                                    v-if="flag2"-->
<!--                                    class="u-flex u-m-t-30 border-b u-p-b-25 u-rela"-->
<!--                            >-->
<!--                                <i-->
<!--                                        class="u-abso iconfont arrow iconxiangshangyuanjiantoushangjiantouxiangshangmianxing"-->
<!--                                        @click="flag2 = !flag2"-->
<!--                                ></i>-->
<!--                                &lt;!&ndash;                                <img&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        class="zsimg"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        src="../../assets/images/applet/index/img8.png"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        alt=""&ndash;&gt;-->
<!--                                &lt;!&ndash;                                />&ndash;&gt;-->
<!--                                &lt;!&ndash;                                <div class="u-m-l-38 u-flex u-flex-col u-col-top">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="u-main-color u-font-28 u-m-b-10">有机证书</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="u-content-color u-font-28 u-m-b-5"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >检测机构：中国质量认证机构</span&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="u-content-color u-font-28 u-m-b-5"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >证书编号：JLK5632030000000</span&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="u-content-color u-font-28 u-m-b-5"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >申请日期：2019/10/21</span&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div v-else class="u-flex border-b u-p-b-10 u-p-t-10">-->
<!--                <span class="u-main-color u-font-28 u-flex-1"-->
<!--                >CMA认证</span-->
<!--                >-->
<!--                                <i-->
<!--                                        class="iconfont u-font-40 green2 iconxiangshangyuanjiantoushangjiantouxiangshangmianxing-copy"-->
<!--                                        @click="flag2 = !flag2"-->
<!--                                ></i>-->
<!--                            </div>-->
<!--                        </div>-->

                    </div>
                    <div class="zhengshu">
                        <div class="u-flex border-b u-p-b-20 u-p-t-58">
                            <img
                                    class="yezi"
                                    src="../../assets/images/applet/index/icon7.png"
                                    alt=""
                            />
                            <span class="u-main-color u-font-28 u-flex-1 u-m-l-14 ">环境监测数据</span>

                        </div>
                        <span class="update block auto">{{sensor_time}}</span>
                    </div>


                    <!-- 指标 -->
                    <div class="u-flex u-flex-wrap indexbox u-m-t-30">
                        <div
                                class="item u-flex"
                                v-for="(item, index) in sensorList"
                                :key="index"
                        >
                            <div class="u-flex u-flex-col u-col-top u-flex-1">
                <span class="green4 u-font-32 blod value">{{
                  item.value
                }}</span>
                                <span class="u-font-24 u-content-color">{{ item.name }}</span>
                            </div>
                            <img class="img" :src="urlList[item.type]" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 产地信息 -->
            <div id="chandi" name="chandi"></div>
            <div class="chandi u-p-40 u-rela">
                <img class="imgbg imgbg2 u-abso" src="../../assets/images/applet/index/bg5.png" alt=""/>
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">产地信息</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <!--                <img class="images" style="z-index: 22;" src="../../assets/images/applet/index/bg4.png">-->
                <div class="info u-rela u-m-t-40 u-rela index-1">
                    <div class="map">
                        <el-amap
                                class="bm-view"
                                vid="vueMap"
                                :zoom="zoom"
                                :center="center"
                                :plugin="plugins"
                                ref="map"
                        >
                            <el-amap-polygon
                                    :path="path"
                                    fillColor="#2b83f9"
                                    fillOpacity="0.5"
                                    strokeWeight="0"
                                    strokeColor="#2b83f9"
                                    strokeOpacity="0.5"
                            ></el-amap-polygon>
                        </el-amap>
                    </div>
                    <span class="block u-m-b-80 u-font-30 white u-p-l-50">江西省鹰潭市潢溪镇数字农业示范区</span>
                    <span class="town u-font-28 white">
                    <i class="iconfont icondizhi u-font-28 u-m-r-10 "></i>
                      <i>潢溪镇</i>
                         </span>
                    <span class="u-font-30 meter block ">历经{{distance}}Km送到您手</span>
                    <p class="white u-font-30 u-m-t-100" style="text-align:center">
                        红糖产地坐落于江西省鹰潭市潢溪镇，种植区域内部署数字化设备10余台，监测数据实时上传潢溪镇人民政府及松浦科技，为甘蔗种植提供更优的种植方案。
                    </p>
                </div>
            </div>
        </div>
        <div class="u-p-30 bg2">
            <!-- 生长过程 -->
            <div id="shengzhang" name="chengzhang"></div>
            <div class="chengzhang u-rela index-1">
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">生长过程</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <div class="u-flex u-m-t-30">
                    <img
                            class="avatar"
                            src="../../assets/images/applet/index/img10.png"
                            alt=""
                    />
                    <div
                            class="panel2 u-flex-1 u-font-28 u-flex u-flex-col u-col-top u-m-l-20"
                    >
            <span class="u-tips-color"
            >我是基地管理员：<i class="red">邱景驰</i></span
            >
                        <span class="u-main-color">以下农事操作，是在我的监督下完成</span>
                    </div>
                </div>

                <div class="descriptimg">
                    <img class="block u-m-t-30 auto" src="../../assets/images/applet/index/img13.png" alt=""/>
                    <img class="block auto" src="../../assets/images/applet/index/img14_01.png" alt=""/>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:1}}">
                        <img src="../../assets/images/applet/index/img14_02.png" alt=""/>
                    </router-link>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:2}}">
                        <img class="block auto" src="../../assets/images/applet/index/img14_03.png" alt=""/>
                    </router-link>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:3}}">
                        <img src="../../assets/images/applet/index/img14_04.png" alt=""/>
                    </router-link>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:4}}">
                        <img class="block auto" src="../../assets/images/applet/index/img14_05.png" alt=""/>
                    </router-link>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:5}}">
                        <img src="../../assets/images/applet/index/img14_06.png" alt=""/>
                    </router-link>
                    <router-link :to="{path:'/applet/grow',query:{code:code,type:6}}">
                        <img src="../../assets/images/applet/index/img14_07.png" alt=""/>
                    </router-link>
                </div>
            </div>
            <!-- 加工仓储 -->
            <div id="jiagong" name="jiagong"></div>
            <div class="jiagong u-m-t-40 u-rela">
                <img
                        class="imgbg imgbg3 u-abso"
                        src="../../assets/images/applet/index/bg5.png"
                        alt=""
                />
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">加工仓储</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <div class="u-flex u-m-t-30 u-rela index-1">
                    <img
                            class="avatar"
                            src="../../assets/images/applet/index/img11.png"
                            alt=""
                    />
                    <div
                            class="panel2 u-flex-1 u-font-28 u-flex u-flex-col u-col-top u-m-l-20"
                    >
            <span class="u-tips-color"
            >我是加工管理员：<i class="red">雷加生</i></span
            >
                        <span class="u-main-color">各个加工环节，是在我的监督下完成</span>
                    </div>
                </div>
                <div class="panel shadow2 u-m-t-40 u-rela index-1">
                    <h3 class="u-flex u-row-center blod u-font-36 green4 auto">
                        手工红糖制作过程
                    </h3>
                    <span class="block u-text-center u-font-28 green4 opacity-60 u-m-t-20"
                    >红糖整个过程需要18到工序</span
                    >
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_1'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_01.png" alt=""/>
                    </router-link>
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_2'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_02.png" alt=""/>
                    </router-link>
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_3'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_03.png" alt=""/>
                    </router-link>
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_4'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_04.png" alt=""/>
                    </router-link>
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_5'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_05.png" alt=""/>
                    </router-link>
                    <router-link class="descriptimg" :to="{path:'/applet/process',query:{code:code,sort_id:'sort_6'}}">
                        <img src="../../assets/images/applet/index/jggc/img11_06.png" alt=""/>
                    </router-link>
                    <h3 class="u-flex u-row-center blod u-font-36 green4 auto">
                        当前仓库环境
                    </h3>
                    <div class="shangchuan u-font-28 u-m-t-40">
                        加工以及仓储信息实时上传
                        <span class="green3">潢溪镇红糖产业中心</span>
                    </div>
                    <div class="u-flex u-row-around u-m-t-30">
                        <div class="u-flex u-flex-col">
                            <span class="u-font-24 u-content-color">仓储温度</span>
                            <span class="green3 blod u-font-46">23℃</span>
                        </div>
                        <div class="u-flex u-flex-col">
                            <span class="u-font-24 u-content-color">仓储湿度</span>
                            <span class="green3 blod u-font-46">39%</span>
                        </div>
                        <div class="u-flex u-flex-col">
                            <span class="u-font-24 u-content-color">烟感报警</span>
                            <span class="green3 blod u-font-46">正常</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 生产企业 -->
            <div id="shengchan" name="shengchan"></div>
            <div class="shengchan u-m-t-40 u-rela">
                <img
                        class="imgbg imgbg3 u-abso"
                        src="../../assets/images/applet/index/bg5.png"
                        alt=""
                />
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">企业信息</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <div class="panel shadow2 u-m-t-30 u-rela index-1">
                    <h3 class="u-flex u-row-center -align-center blod u-font-36 green4 auto">
                        鹰潭余江区潢溪晓花匠农业<br/>产业发展专业合作社联合社
                    </h3>
                    <div class="swiper u-m-t-40" id="swiper2">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="item">
                                        <img style="width: 100%"
                                             src="../../assets/images/applet/index/company.png"
                                             alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <!-- 如果需要分页器 -->
                            <div class="swiper-pagination"></div>

                            <!-- 如果需要导航按钮 -->
                            <!-- <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div> -->

                            <!-- 如果需要滚动条 -->
                            <!--    <div class="swiper-scrollbar"></div>-->
                        </div>
                    </div>
                    <div class="u-flex u-row-between u-m-t-30">

                        <div class="u-flex u-flex-col">
                            <viewer>
                                <img
                                        class="scicon"
                                        :src="yyzz_src" :data-source="yyzz_b_src"
                                        alt=""
                                />
                            </viewer>
                            <span class="u-font-28 u-tips-colot">出品商</span>
                            <span class="u-font-16 white bggreen heyan">已核验</span>
                        </div>
                        <div class="u-flex u-flex-col">
                            <viewer>
                                <img
                                        class="scicon"
                                        :src="yyzz_src" :data-source="yyzz_c_src"
                                        alt=""
                                />
                            </viewer>
                            <span class="u-font-28 u-tips-colot">生产商</span>
                            <span class="u-font-16 white bggreen heyan">已核验</span>
                        </div>
                        <div class="u-flex u-flex-col">
                            <viewer>
                                <img
                                        class="scicon"
                                        :src="scxk_src" :data-source="scxk_b_src"
                                        alt=""
                                />
                            </viewer>
                            <span class="u-font-28 u-tips-colot">生产许可</span>
                            <span class="u-font-16 white bggreen heyan">已核验</span>
                        </div>
                        <div class="u-flex u-flex-col">
                            <a :href="tel">
                                <img
                                        class="scicon"
                                        src="../../assets/images/applet/index/icon11.png"
                                        alt=""
                                />
                            </a>
                            <span class="u-font-28 u-tips-colot">客服电话</span>
                            <span class="u-font-16 white bggreen heyan">已核验</span>
                        </div>
                    </div>
                    <p style="text-indent:2em" class="u-font-32 u-cotnent-color u-m-t-40">
                       潢溪镇以城乡融合发展为契机，引进清华大学孵化的高新技术企业北京松浦科技，结合潢溪镇当地红糖产业优势，发展甘蔗生产-红糖加工数字农业，走新型集体经济为主的农村共同富裕道路。
                    </p>
                    <p style="text-indent:2em" class="u-font-32 u-cotnent-color u-m-t-40">
                        潢溪镇成立镇级集体经济合作社，走政府加企业加合作社加农户经营模式。以韬奋文化为切入点，结合邹韬奋先生“甘为花匠”的人生观和为人民服务的宗旨，创立并打造“晓花匠”红糖品牌，让韬奋精神和潢溪红糖家喻户晓。
                    </p>
                </div>
            </div>
            <!-- 购买 -->
            <!--            <div class="radius-30 buybox u-flex u-p-15 u-m-t-50 u-rela shadow2">-->
            <!--                <img-->
            <!--                        class="img"-->
            <!--                        src="../../assets/images/applet/index/img9.png"-->
            <!--                        alt=""-->
            <!--                />-->
            <!--                <div class="u-m-l-10 u-flex-1 u-flex u-flex-col u-col-top">-->
            <!--                    <span class="u-font-32 u-main-color">哓花匠红糖</span>-->
            <!--                    <span class="u-font-26 u-content-color"-->
            <!--                    >看得见溯源匠心，吃得出健康安心</span-->
            <!--                    >-->
            <!--                </div>-->
            <!--                <div class="btn u-flex u-row-center u-font-28 blod white">去购买</div>-->
            <!--            </div>-->
        </div>
    </div>
</template>
<script>
    import Swiper from "swiper";

    export default {
        name: "",
        data() {
            return {
                yyzz_src: require('../../assets/images/applet/index/icon8.png'),
                yyzz_b_src: require('../../assets/images/applet/index/xhj.jpeg'),
                yyzz_c_src: require('../../assets/images/applet/index/ynhzs.jpeg'),
                scxk_src: require('../../assets/images/applet/index/icon10.png'),
                scxk_b_src: require('../../assets/images/applet/index/scxk.jpeg'),
                tel: 'tel:400-018-5606',
                zoom: 16,
                qwjg_images: [
                    require('../../assets/images/applet/index/qwjg/0.jpeg'),
                    require('../../assets/images/applet/index/qwjg/1.jpeg'),
                    require('../../assets/images/applet/index/qwjg/2.jpeg'),
                    require('../../assets/images/applet/index/qwjg/3.jpeg'),
                    require('../../assets/images/applet/index/qwjg/4.jpeg'),
                ],
                plugins: [
                    {
                        // 地图类型
                        pName: "MapType",
                        defaultType: 1,
                        showRoad: true,
                        dragEnable: false,
                        zoomEnable:false,
                    },
                ],
                center: [116.894539, 28.315328],
                path: [
                    [116.8950103595853, 28.313817221738653],
                    [116.89452085644008, 28.31465430552327],
                    [116.89462881535293, 28.31467260558883],
                    [116.8944873288274, 28.315120070728067],
                    [116.89448498189451, 28.3157620441401],
                    [116.8954210728407, 28.3154987617199],
                    [116.89548678696156, 28.315711276728646],
                    [116.89607821404935, 28.315531819638057],
                    [116.89607821404935, 28.315531819638057],
                    [116.89598299562932, 28.31477856880977],
                    [116.89636789262296, 28.3147856526982],
                    [116.89635314047338, 28.314609735995596],
                    [116.89560614526272, 28.314566051937092],
                    [116.89562626183036, 28.313995796230074],
                ],
                code: 0,
                navcur: 0,
                navlist: [
                    {
                        name: "权威监管",
                        url: "#quanwei",
                    },
                    {
                        name: "产地信息",
                        url: "#chandi",
                    },
                    {
                        name: "生长过程",
                        url: "#shengzhang",
                    },
                    {
                        name: "加工仓储",
                        url: "#jiagong",
                    },
                    {
                        name: "生产企业",
                        url: "#shengchan",
                    },
                ],
                flag1: true,
                flag2: false,
                flag3: false,
                flag4: false,
                urlList: {
                    temp: require("../../assets/images/applet/cli/cli1.png"),
                    humidity: require("../../assets/images/applet/cli/cli2.png"),
                    direction: require("../../assets/images/applet/cli/cli3.png"),
                    speed: require("../../assets/images/applet/cli/cli4.png"),
                    pressure: require("../../assets/images/applet/cli/cli7.png"),
                    rainfall: require("../../assets/images/applet/cli/cli5.png"),
                    evaporation: require("../../assets/images/applet/cli/cli6.png"),
                    soil_temp: require("../../assets/images/applet/cli/cli8.png"),
                    soil_moisture: require("../../assets/images/applet/cli/cli9.png"),
                    ec: require("../../assets/images/applet/cli/cli10.png"),
                    illuminance: require("../../assets/images/applet/cli/cli11.png"),
                },
                sensor_time: '暂未更新',
                distance: '-',
                sensorList: [
                    {
                        type: 'temp',
                        name: "空气温度",
                        value: "-℃",
                    },
                    {
                        type: 'humidity',
                        name: "空气湿度",
                        value: "-℃",
                    },
                    {
                        type: 'direction',
                        name: "风向",
                        value: "-",
                    },
                    {
                        type: 'speed',
                        name: "风速",
                        value: "-m/s",
                    },
                    {
                        type: 'rainfall',
                        name: "降雨量",
                        value: "-mm",
                    },
                    {
                        type: 'evaporation',
                        name: "蒸发量",
                        value: "-mm",
                    },
                    {
                        type: 'pressure',
                        name: "大气压",
                        value: "-pa",
                    },
                    {
                        type: 'soil_temp',
                        name: "土壤温度",
                        value: "-℃",
                    },
                    {
                        type: 'soil_moisture',
                        name: "土壤湿度",
                        value: "-%",
                    },
                    {
                        type: 'ec',
                        name: "土壤EC值",
                        value: "-us/cm",
                    },
                    {
                        type: 'illuminance',
                        name: "光照度",
                        value: "-us/cm",
                    },
                ],
            };
        },
        mounted() {
            new Swiper("#swiper .swiper-container", {
                loop: true,
                autoplay: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
            new Swiper("#swiper2 .swiper-container", {
                loop: true,
                autoplay: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
            this.code = this.$route.query.code;
            this.$axios.get('traceInfo', {
                params: {
                    code: this.code,
                }
            }).then(res => {
                //请求的数据存储在res.data 中
                if (res.data.status == 200) {
                    // console.log(res.data.data.supervise);
                    // this.sensorList = res.data.data.sensor;//传感器数据
                    this.sensor_time = res.data.data.supervise.sensor_time;
                    this.sensorList = res.data.data.supervise.sensor;
                    this.distance = res.data.data.supervise.distance;
                }

                // console.log(this.good);
            }).catch(function (error) {
                console.log(error);
            });


        },
        methods: {
            handlezhengshu2() {
                this.flag2 = true;
                console.log(this.flag2);
            },
            handletab(index, url) {
                this.navcur = index;
                // 滚动
                // console.log(document.querySelector(url));
                document.querySelector(url).scrollIntoView(true);
            },
        },
    };
</script>
